<template>
    <div class="vehicleTrack" v-loading="loading">
        <template  v-if="flag.showMap">
            <!-- 地图组件 -->
            <TXmap style="width: 100%;height: 750px;" ref="map" :mapdialog="flag.showMap" :list="mapList"
                :maptype="maptype" :posinfo="posinfo">
            </TXmap>

            <!-- 运单信息 -->
            <div class="order-info" v-if="orderDetail">
                <div class="info-title" @click="changeMoreDetail(1)">
                    运单信息<i style="margin-left: 8px;" :class="showOrderInfo ? 'el-icon-arrow-up': 'el-icon-arrow-down'"></i>
                </div>
                <el-collapse-transition>
                    <div class="many-info" v-show="showOrderInfo">
                        <div class="info">
                            <span>运 单 号:</span>
                            <span>{{orderDetail.OrderID}}</span>
                        </div>
                        <div class="info">
                            <span>货品名称:</span>
                            <span>{{orderDetail.GoodsName}}</span>
                        </div>
                        <div class="info">
                            <span>货品单位:</span>
                            <span>{{orderDetail.GoodsUnit}}</span>
                        </div>
                        <div class="info">
                            <span>货品数量:</span>
                            <span>{{orderDetail.LoadCount}}</span>
                        </div>
                        <div class="info">
                            <span>货品总重(吨):</span>
                            <span>{{orderDetail.GoodsTotalWeight}}</span>
                        </div>
                        <div class="info">
                            <span>货品价值:</span>
                            <span style="color:#FF8B17;">{{orderDetail.GoodsValue | formatMoney}}</span>
                        </div>
                        <div class="info">
                            <span>收 款 人:</span>
                            <span>{{orderDetail.PayeeName}}</span>
                        </div>
                        <div class="info">
                            <span>提 货 人:</span>
                            <span>{{orderDetail.LoaderName}}</span>
                        </div>
                        <div class="info">
                            <span>收 货 人:</span>
                            <span>{{orderDetail.SignUserName}}</span>
                        </div>
                        <div class="info">
                            <span>装货时间:</span>
                            <span v-if="orderDetail.OrderType != 2">{{orderDetail.LoadingStartTime}}</span>  
                            <span v-else>{{orderDetail.DriverOrders[seizeDriverIndex].RealityLoadingStartTime}}</span> 
                        </div>
                         <div class="info">
                            <span>卸货时间:</span>
                            <span v-if="orderDetail.OrderType != 2">{{orderDetail.LoadingEndTime}}</span>
                            <span v-else>{{orderDetail.DriverOrders[seizeDriverIndex].RealityLoadingEndTime}}</span> 
                        </div>
                        <div class="info">
                            <span>起 始 地:</span>
                            <el-tooltip effect="dark" :content="orderDetail.StartDetailedAddress" placement="top-start">
                                <span>{{substrLength(orderDetail.StartDetailedAddress)}}</span>
                            </el-tooltip>
                        </div>
                        <div class="info">
                            <span>到 达 地:</span>
                            <el-tooltip effect="dark" :content="orderDetail.EndDetailedAddress" placement="top-start">
                                <span>{{substrLength(orderDetail.EndDetailedAddress)}}</span>
                            </el-tooltip>
                        </div>
                        <div class="info">
                            <span>装 货 地:</span>
                            <el-tooltip effect="dark" :content="orderDetail.UpDetailedAddress" placement="top-start">
                                <span>{{substrLength(orderDetail.UpDetailedAddress)}}</span>
                            </el-tooltip>
                        </div>
                        <div class="info">
                            <span>卸 货 地:</span>
                            <el-tooltip effect="dark" :content="orderDetail.UnDetailedAddress" placement="top-start">
                                <span>{{substrLength(orderDetail.UnDetailedAddress)}}</span>
                            </el-tooltip>
                        </div>
                        <div class="info">
                            <span>车牌号码:</span>
                            <span>{{orderDetail.CarNumber || orderDetail.DriverOrders[seizeDriverIndex].CarNumber}}</span>
                        </div>
                        <div class="info">
                            <span>车辆类型:</span>
                            <span v-if="orderDetail.OrderType != 2">{{orderDetail.CarDetails ? orderDetail.CarDetails.VehicleTypeName : '' }}</span>
                            <span v-else>{{orderDetail.DriverOrders[seizeDriverIndex].CarDetails.VehicleTypeName || '' }}</span>
                        </div>
                         <div class="info" v-if="orderDetail.OrderType == 2">
                            <span>装货数量:</span>
                            <span>{{orderDetail.DriverOrders[seizeDriverIndex].LoadCount}}</span>
                        </div>
                        <div class="info" v-if="orderDetail.OrderType == 2">
                            <span>单价:</span>
                            <span style="color:#FF8B17;">{{orderDetail.CarrierPrice | formatMoney}}</span>
                        </div>
                        <div class="info">
                            <span>运费:</span>
                            <span v-if="orderDetail.OrderType != 2" style="color:#FF8B17;">{{orderDetail.CarrierPrice| formatMoney}}</span>
                            <span v-else style="color:#FF8B17;">{{orderDetail.DriverOrders[seizeDriverIndex].Money | formatMoney}}</span>
                        </div>
                    </div>
                </el-collapse-transition>
            </div>

            <!-- 打卡记录 -->
            <div class="clockIn-info">
                <div class="info-title" @click="changeMoreDetail(2)">
                    打</br>卡</br> 记</br>录<i
                        :class="showClockInInfo ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"></i>
                </div>
                <transition name="el-zoom-in-center">
                    <el-table v-show="showClockInInfo" :data="orderDetail.OrderType != 2 ? clockInList :orderDetail.DriverOrders[seizeDriverIndex].CardRecord" height="288" style="width: 100%"
                        :show-header="false">
                        <el-table-column prop="index" label="序号" width="50" align="center">
                            <template slot-scope="scope">
                                <div class="waterIcon-box">
                                    <img class="waterIcon" :src="scope.row.RecordType == 0 ? startIcon :
                                    scope.row.RecordType == 2 ? endIcon : indexIcon
                                    ">
                                    <span class="scale">{{ scope.row.RecordType == 0 ? '起' :
                                    scope.row.RecordType == 2 ? '终' :
                                    (scope.row.index  - 1) || scope.$index }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="RecordTime" label="日期" width="150" align="center">
                        </el-table-column>
                        <el-table-column prop="Address" label="地址" width="230" show-overflow-tooltip align="center">
                        </el-table-column>
                        <el-table-column prop="RecordTypeName" label="操作" width="80" align="center">
                        </el-table-column>
                        <el-table-column prop="RecordType" label="操作" width="50" align="center">
                            <template slot-scope="scope">
                                <el-link v-if="scope.row.RecordType != 1" style="color: #0f5fff;" @click="getPreviewImg(scope.row.RecordID)">图片</el-link>
                                <span v-else> - </span>
                            </template>   
                        </el-table-column>
                    </el-table>
                </transition>
            </div>

            <!-- 预览图片 -->
            <el-image
            style="display:none;"
            ref="previewImg"
            :preview-src-list="previewImg"
            ></el-image>
        </template>
    </div>
</template>

<script>
    import {
        HtwPunchingGetCardRecord,
        GetHtwlCarLocationAsync,
        GetHtwOrdersFirst,
        HtwPunchingCardRecordFist
    } from "@/api/contractLogistics/createPlan";
    import TXmap from "@/components/commonCmpt/TXmap"
    export default {
        props: {
            currentOrder: { //运单信息
                type: String,
                default () {
                    return {}
                }
            },
            orderDetail: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                flag: {
                    showMap: false, // 车辆信息弹窗
                },
                showOrderInfo: true, // 显示运单信息
                showClockInInfo: false, // 显示打卡记录
                clockInList: [], // 打卡记录列表
                maptype: '',
                posinfo: {},
                mapList: [], // 轨迹列表
                loading: false,
                // orderDetail: {
                //     CarDetails: {
                //         VehicleTypeName: ''
                //     }
                // },
                startIcon: require('../../assets/image/startIcon.png'),
                indexIcon: require('../../assets/image/indexIcon.png'),
                endIcon: require('../../assets/image/endIcon.png'),
                previewImg: [], // 预览图片
                seizeDriverIndex: 0, // 查看第几个抢单司机
            };
        },
        components: {
            TXmap
        },
        methods: {
            // 获取订单详情
            // GetHtwOrdersFirst() {
            //     GetHtwOrdersFirst(this.currentOrder).then(res => {
            //         this.orderDetail = res.data
            //     })
            // },

            // 获取打卡记录
            getClockInList() {
                HtwPunchingGetCardRecord({
                    pageSize: 1000,
                    pageIndex: 1,
                    orderID: this.currentOrder,
                    orderBy: "Asc",
                    isAuto: "否"
                }).then(res => {
                    this.clockInList = res.data.DataList.map((item, index) => {
                        item.index = index + 1;
                        return item
                    })
                })
            },

            // 显示隐藏效果
            changeMoreDetail(type) {
                if (type == 1) {
                    this.showOrderInfo = !this.showOrderInfo
                } else {
                    this.showClockInInfo = !this.showClockInInfo
                }
            },

            //打开地图
            openMap(item) { 
                let driverId = ''; 
                if (item.OrderType == 2) { 
                    driverId = `?recordDriverId=${item.DriverOrders[this.seizeDriverIndex].RecordID}`
                }

                this.loading = true
                GetHtwlCarLocationAsync(item.OrderID, driverId).then((res) => {
                    this.maptype = ""
                    this.mapList = res.data && res.data.map( item => {
                        item.Lat = item.AddressLat
                        item.Lng = item.AddressLon
                        item.GPSTime = item.RecordTime
                        return item
                    });
                    this.flag.showMap = true;
                }).finally(() => {
                    this.loading = false
                });
            },

            // 车辆最新轨迹查询
            getCarNewLocation(item) {
                const loading = this.$loading({
                    lock: true,
                    text: '位置请求中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                GetCarNewLocation({
                    carNumber: item.VehicleCarNumber
                }).then(res => {
                    this.maptype = 'position'
                    this.posinfo = res.data || {};
                    this.posinfo.carNumber = item.VehicleCarNumber
                    this.flag.showMap = true;

                }).finally(() => {
                    loading.close()
                })
            },

            // 截取地址
            substrLength(address) {
                if(!address) return null;
                if(address.length > 15) {
                    return address.substr(0,15) + '...' 
                } else {
                    return address;
                }
            },

            // 获取装卸货图片,打开预览
            getPreviewImg(RecordID) {
                this.loading = true;
                HtwPunchingCardRecordFist(RecordID).then( res => {
                    this.previewImg = res.data.ImgUrls;
                    this.$nextTick( () => {
                        this.$refs.previewImg.clickHandler();
                    })
                }).finally(() => {
                    this.loading = false;
                })
            }

        }
    };
</script>

<style lang="scss" scoped>
    .vehicleTrack {
        position: relative;

        .order-info {
            position: absolute;
            top: 0;
            left: 1.5%;
            color: #333333;
            width: 97%;
            background-color: #fff;
            z-index: 9999;

            .info-title {
                height: 36px;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
                user-select: none;
            }

            .many-info {
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                padding: 8px 16px;
                box-sizing: border-box;

                .info {
                    width: 25%;
                    margin: 8px 0;

                    span {
                        font-weight: bolder;

                        &:nth-child(2) {
                            font-weight: normal;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }

        .clockIn-info {
            height: 288px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            z-index: 9999;

            .info-title {
                width: 36px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                background-color: #0f5fff;
                color: #fff;
                line-height: 40px;
                cursor: pointer;
                user-select: none;
            }
            
            .waterIcon-box {
                position: relative;
                

                .waterIcon {
                    min-width: 18px;
                    height: 22px;
                    margin: 0 atuo;
                }

                span {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 12px;
                    color: #fff;
                }

                .scale {
                    transform: scale(0.8);
                }
            }
            
        }
    }

    /deep/.TXmap #container{
        height: 680px !important;
    }
</style>

<style>
    .customClass .el-dialog__header,
    .customClass .el-dialog__body {
      padding: 0 !important;
    }
    
    .customClass .el-dialog__headerbtn {
      z-index: 10000;
      top: 2%;
      right: 5%;
    }
    
</style>