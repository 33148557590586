import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

//获取货主信息
export const getenterPriseInfo = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/ConsInfo/GetenterPriseInfo`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//创建订单
export const createOrder = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/HTWL/CreateOrder`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//获取服务商列表
export const getPartnerListNoPage = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/HTWL/GetPartnerListNoPage`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 创建合同物流订单
export const createOrderHtw = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/PostCreateOrderHtw`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取合同物流列表
export const getOrderHtwList = (isFaciparamslitator, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/ZlOrderHtw/${isFaciparamslitator}`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 服务商端 - 获取列表数据
export const GetFacilitatorZlOrderHtw = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/GetFacilitatorZlOrderHtw`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 司机查询车牌
export const driverVehicleRelation = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/gtw/api/v2/HTWL/GetDriverVehicleAsync?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询更多时间
export const GetOrderTimeAsync = (params, type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/gtw/api/v2/HTWL/GetOrderTimeAsync/${params}/${type}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改订单状态
export const PutOrderStatus = (params) => {
  return new Promise((resolve, reject) => {
    axios.put(`${host}/gtw/api/v2/HTWL/PutOrderStatus`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 服务商指派司机、车辆
export const SetProviDerdriver = (orderId, driverId, carNumber) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${host}/gtw/api/v2/HTWL/SetProviDerdriver/${orderId}/${driverId}?carNumber=${carNumber}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 服务商拒绝承运
export const SetCancelOrderProviDerdriver = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${host}/gtw/api/v2/HTWL/SetCancelOrderProviDerdriver`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 服务商取消司机
export const SetCancelProviDerdriver = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${host}/gtw/api/v2/HTWL/SetCancelProviDerdriver`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取订单详情
export const GetHtwOrdersFirst = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/gtw/api/v2/HTWL/GetHtwOrdersFirst/${params}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改订单信息
export const updataOrderHtw = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${host}/gtw/api/v2/HTWL/PutCreateOrderHtw/${params.OrderID}`,
        params
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除订单
export const DeleteOrder = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${host}/gtw/api/v2/HTWL/DeleteOrder/${params}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取打卡记录
export const HtwPunchingGetCardRecord = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/HtwPunchingGetCardRecord`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取所有打卡经纬度
export const GetHtwlCarLocationAsync = (params, recordDriverId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/gtw/api/v2/HTWL/GetHtwlCarLocationAsync/${params}${recordDriverId}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 发往运单库
export const GoWaybillAsync = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/gtw/api/v2/HTWL/GoWaybillAsync`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 获取平台货主信息
export const GetPartnerListAsync = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/GetPartnerListAsync`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取打卡记录详情
export const HtwPunchingCardRecordFist = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/gtw/api/v2/HTWL/HtwPunchingCardRecordFist/${params}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取订单拒绝理由
export const GetStatusMessageAsync = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/gtw/api/v2/HTWL/GetStatusMessageAsync/${params}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取货主名下司机列表
// 获取平台货主信息
export const GetDevCarAsync = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/Driver/GetDevCarAsync`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 支付订单
export const PaymentOrder = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/Payment`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改运费
export const UpdateFreight = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/gtw/api/v2/HTWL/UpdateFreight`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
