<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      class="htwl-pdf"
    >
      <div id="printObjAll1" v-if="currentOrderObj != undefined">
        <div
          class="pdf-title"
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'self-end',
            color: '#000',
          }"
        >
          <div class="title-logo" :style="{ width: '25%' }"></div>
          <div
            class="title-text"
            :style="{ width: '50%', fontSize: '28px', fontWeight: 'bold' }"
          >
           {{pdfModelTitle}}
          </div>
          <div class="title-num" :style="{ width: '25%' }">运单号：{{ htwOrdersId }}</div>
        </div>

        <div
          class="pdf-desc"
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '50px 0 30px',
          }"
        >
          <div class="pdf-msg" :style="{ display: 'flex', alignItems: 'center' }">
            <div class="msg-title">发货人:</div>
            <div class="msg-value">{{ currentOrderObj.OwnerName }}</div>
          </div>
          <div class="pdf-msg" :style="{ display: 'flex', alignItems: 'center' }">
            <div class="msg-title">托运日期:</div>
            <div class="msg-value" :style="{ color: 'blue', marginLeft: '12px' }">
              {{ currentOrderObj.DateUppercase }}
            </div>
          </div>
        </div>

        <el-descriptions :column="3" border>
          <el-descriptions-item
            v-for="(it, idx) in entity"
            :key="idx"
            :label="it.name"
            :labelStyle="{
              color: '#333',
            }"
            :span="it.span || 1"
          >
            <template v-if="it.type == 'number'">
              <span style="color: #ff8b17; font-weight: 600">
                {{ currentOrderObj[it.key] | formatMoney }}
              </span>
            </template>
            <template v-else>
              <span style="font-weight: 600">
                {{
                  it.key1
                    ? currentOrderObj[it.key][it.key1] || "暂无"
                    : currentOrderObj[it.key] || "暂无"
                }}
              </span>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button v-print="printObj" type="primary" size="small" icon="el-icon-printer"
          >打印</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-download"
          @click="savePDF()" 
          >保存PDF</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const entity = [
  {
    name: "起始地",
    span: 2,
    key: "StartDetailedAddress",
  },
  {
    name: "装货时间",
    span: 1,
    key: "StatusChangeTime",
  },
  {
    name: "到达地",
    span: 2,
    key: "EndDetailedAddress",
  },
  {
    name: "卸货时间",
    span: 1,
    key: "LoadingEndTime",
  },
  {
    name: "货品名称",
    key: "GoodsName",
  },
  {
    name: "货品单位",
    key: "GoodsUnit",
  },
  {
    name: "货品数量",
    key: "LoadCount",
  },
  {
    name: "货物总重（吨）",
    key: "GoodsTotalWeight",
  },
  {
    name: "货品价值（元）",
    key: "GoodsValue",
    type: "number",
  },
  {
    name: "运费（元）",
    key: "CarrierPrice",
    type: "number",
  },
  {
    name: "收款人",
    key: "PayeeName",
  },
  {
    name: "收货人",
    key: "SignUserName",
  },
  {
    name: "提货人",
    key: "LoaderName",
  },
  {
    name: "司机姓名",
    key: "DriverName",
  },
  {
    name: "车牌号",
    key: "CarDetails",
    key1: "VehicleCarNumber",
  },
  {
    name: "车辆类型",
    key: "CarDetails",
    key1: "VehicleTypeName",
  },
  {
    name: "司机联系电话",
    key: "DriverPhone",
  },
  {
    name: "提货人电话",
    key: "LoaderPhone",
  },
  {
    name: "收款人电话",
    key: "PayeePhone",
  },
  {
    name: "合计（大写）",
    span: 3,
    key: "CarrierPriceUppercase",
  },
];

import htmlToPdf from "@/utils/htmlToPdf";
import { GetHtwOrdersFirst } from "@/api/contractLogistics/createPlan";
export default {
  data() {
    return {
     pdfModelTitle:"运服通物流货物托运单",
      printObj: {
        id: "printObjAll1",
        popTitle: "",
        documentTitle: "",
        type: "html", // 打印的元素类型
        previewBeforeOpenCallback() {
          console.log('正在加载预览窗口')
        },
        previewOpenCallback() {
          console.log('已经加载完预览窗口')
        },
        beforeOpenCallback(vue) {
          vue.printLoading = true
          console.log('打开之前')
        },
        openCallback(vue) {
          vue.printLoading = false
          console.log('执行了打印')
        },
        closeCallback() {
          console.log('关闭了打印工具')
        },
        clickMounted(vue){
          console.log('点击了打印按钮');
         
        }
 
      },
      htwOrdersId: undefined,
      currentOrderObj: undefined,
      dialogVisible: false,
      entity,
    };
  },
  methods: {
    // 保存为pdf
    savePDF() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //   // 定时器模拟按钮loading动画的时间
      setTimeout(() => {
        // 调用htmlToPdf工具函数
        htmlToPdf.getPdf(this.pdfModelTitle, "#printObjAll1");
        loading.close();
      }, 100);
    },
    handleClose() {
      this.currentOrderObj = undefined;
      this.dialogVisible = false;
    },
    init(htwOrdersId) {
      this.htwOrdersId = htwOrdersId;
      this.dialogVisible = true;
      const loading = this.$loading({
        lock: true,
        text: "获取运单信息...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetHtwOrdersFirst(this.htwOrdersId)
        .then((res) => {
          this.$nextTick(() => {
            this.currentOrderObj = res.data || undefined;
          });
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.htwl-pdf {
  ::v-deep .el-dialog__body {
    padding: 0px 20px;
  }

  .pdf-title {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    color: #000;

    .title-logo,
    .title-num {
      width: 25%;
    }

    .title-text {
      font-size: 28px;
      font-weight: bold;
    }
  }

  .pdf-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 30px;

    .pdf-msg {
      display: flex;
      align-items: center;
 

      .msg-value {
        color: blue;
        margin-left: 12px;
        // font-size: 18px;
      }
    }
  }
}
</style>

<style media="print">
  @page {
    size: auto;
    margin: 3mm;
  }
  html {
    background-color: #ffffff;
    margin: 0px;
  }
  body {
    border: solid 1px #ffffff;
  }
</style>
<style lang="scss" scoped>
  @media print {
    #printView {
      display: block;
      width: 100%;
      overflow: hidden;
    }
  }
</style>

 

