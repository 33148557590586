// 设置运单状态
export const setOrderStatus = function (status) {
    switch (status) {
        case "待服务商确认":
        case "待司机确认":
            return {
                name: "待确定", bg: "#FDF6EC", text: "#FF8B17"
            };
        case "服务商已确认":
        case "司机已确认":
            return {
                name: "已接受", bg: "#EBFFF2", text: "#00E855"
            };
        case "司机待装货":
            return {
                name: "待运输", bg: "#FDF6EC", text: "#FF8B17"
            };
        case "服务商拒绝":
        case "服务商已拒绝":
        case "司机已拒绝":
            return {
                name: "已拒绝", bg: "#FFF2F2", text: "#FF2323"
            };
        case "货主已取消":
        case "司机已取消":
            return {
                name: "已取消", bg: "#F5F5F5", text: "#999999"
            };
        case "运输中":
            return {
                name: "运输中", bg: "#EBDFFF", text: "#6D19FF"
            };
        case "已完成":
            return {
                name: "已完成", bg: "#EBFFF2", text: "#00E855"
            };
        case "已发往运单库":
            return {
                name: "已发往运单库", bg: "#E1EEFF", text: "#0F5FFF"
            };
        case "已支付":
            return {
                name: "已支付", bg: "#EBFFF2", text: "#00E855"
            };
        default:
            return {
                name: status, bg: "#ccc", text: "#fff"
            };
    }
}