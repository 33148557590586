var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"htwl-pdf",attrs:{"visible":_vm.dialogVisible,"width":"50%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.currentOrderObj != undefined)?_c('div',{attrs:{"id":"printObjAll1"}},[_c('div',{staticClass:"pdf-title",style:({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'self-end',
          color: '#000',
        })},[_c('div',{staticClass:"title-logo",style:({ width: '25%' })}),_c('div',{staticClass:"title-text",style:({ width: '50%', fontSize: '28px', fontWeight: 'bold' })},[_vm._v(" "+_vm._s(_vm.pdfModelTitle)+" ")]),_c('div',{staticClass:"title-num",style:({ width: '25%' })},[_vm._v("运单号："+_vm._s(_vm.htwOrdersId))])]),_c('div',{staticClass:"pdf-desc",style:({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '50px 0 30px',
        })},[_c('div',{staticClass:"pdf-msg",style:({ display: 'flex', alignItems: 'center' })},[_c('div',{staticClass:"msg-title"},[_vm._v("发货人:")]),_c('div',{staticClass:"msg-value"},[_vm._v(_vm._s(_vm.currentOrderObj.OwnerName))])]),_c('div',{staticClass:"pdf-msg",style:({ display: 'flex', alignItems: 'center' })},[_c('div',{staticClass:"msg-title"},[_vm._v("托运日期:")]),_c('div',{staticClass:"msg-value",style:({ color: 'blue', marginLeft: '12px' })},[_vm._v(" "+_vm._s(_vm.currentOrderObj.DateUppercase)+" ")])])]),_c('el-descriptions',{attrs:{"column":3,"border":""}},_vm._l((_vm.entity),function(it,idx){return _c('el-descriptions-item',{key:idx,attrs:{"label":it.name,"labelStyle":{
            color: '#333',
          },"span":it.span || 1}},[(it.type == 'number')?[_c('span',{staticStyle:{"color":"#ff8b17","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.currentOrderObj[it.key]))+" ")])]:[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(it.key1 ? _vm.currentOrderObj[it.key][it.key1] || "暂无" : _vm.currentOrderObj[it.key] || "暂无")+" ")])]],2)}),1)],1):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{directives:[{name:"print",rawName:"v-print",value:(_vm.printObj),expression:"printObj"}],attrs:{"type":"primary","size":"small","icon":"el-icon-printer"}},[_vm._v("打印")]),_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-download"},on:{"click":function($event){return _vm.savePDF()}}},[_vm._v("保存PDF")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }